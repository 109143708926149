import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { FirstFooter, SecondFooter } from "./styleComponent";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

function Index({ dataCategoryProduct, dataService, dataAboutUs }) {
  const history = useHistory();
  return (
    <>
      {/* MEDSOS */}
      <Grid
        style={{
          backgroundColor: "#2255a6",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          style={{
            maxWidth: 950,
            padding: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <b style={{ marginRight: 20, color: "white" }}>Follow Us</b>
          <FacebookIcon
            style={{ marginRight: 20, cursor: "pointer", color: "white" }}
            onClick={() =>
              window.open("https://www.facebook.com/PT.PolaProserveEngineering")
            }
          />
          <InstagramIcon
            style={{ cursor: "pointer", color: "white" }}
            onClick={() =>
              window.open(
                "https://www.instagram.com/pt.polaproserveengineering/?igshid=MzRlODBiNWFlZA=="
              )
            }
          />
        </Grid>
      </Grid>

      <Grid
        style={{
          backgroundColor: "#aeaeae",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          style={{
            maxWidth: 950,
            padding: "20px 0px",
            display: "flex",
          }}
        >
          <FirstFooter item xs={12} md={4}>
            <h3 style={{ fontFamily: "Work Sans", margin: 0 }}>
              PT Pola Proserve Engineering
            </h3>
            <h4
              style={{ fontFamily: "Work Sans", margin: 0, marginBottom: 20 }}
            >
              The Best Service For Rotary Machinery Problems
            </h4>
            <b style={{ fontFamily: "Work Sans", fontSize: 15 }}>Head Office</b>
            <p style={{ fontSize: 14 }}>
              Jl. Tekno 4 Blok E3 No.1 Taman Tekno BSD City Sektor XI Tanggerang
              15314{" "}
            </p>
            <p style={{ fontSize: 14 }}>Indonesia</p>
            <p style={{ fontSize: 14 }}>Phone : +62 21 7588 2418</p>
            <p style={{ fontSize: 14 }}>Fax : +62 21 7587 4247</p>
            <p style={{ fontSize: 14 }}>Mail : proserve@indo.net.id</p>
            <p style={{ fontSize: 14 }}>Website : www.proserve.co.id</p>
          </FirstFooter>

          <SecondFooter item md={8}>
            <Grid style={{ width: "33%" }}>
              <b style={{ fontFamily: "Work Sans", fontSize: 15 }}>
                Products Types
              </b>
              {dataCategoryProduct.map((categoryProduct, index) => (
                <p
                  style={{ fontSize: 14, cursor: "pointer" }}
                  onClick={() =>
                    history.push("/product", { id: categoryProduct.id })
                  }
                  key={`categoryProduct-${index}`}
                >
                  {categoryProduct.name}
                </p>
              ))}
            </Grid>

            <Grid style={{ width: "33%" }}>
              <b style={{ fontFamily: "Work Sans", fontSize: 15 }}>
                Our Services
              </b>
              {/* <p style={{ fontSize: 14 }}>Overview</p> */}
              {dataService.map((service, index) => (
                <p
                  style={{ fontSize: 14, cursor: "pointer" }}
                  onClick={() => history.push("/service", { id: service.id })}
                  key={`service-${index}`}
                >
                  {service.title}
                </p>
              ))}
            </Grid>

            <Grid style={{ width: "33%" }}>
              <b style={{ fontFamily: "Work Sans", fontSize: 15 }}>
                Our Company
              </b>
              {dataAboutUs.map((aboutus, index) => (
                <p
                  style={{ fontSize: 14, cursor: "pointer" }}
                  onClick={() => history.push("/about-us", { id: aboutus.id })}
                  key={`about-us-${index}`}
                >
                  {aboutus.title}
                </p>
              ))}
            </Grid>
          </SecondFooter>
        </Grid>
      </Grid>

      <Grid
        style={{
          backgroundColor: "#303030",
          padding: "30px auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 50,
        }}
      >
        <p style={{ color: "#ffffff", fontSize: 10 }}>
          © Copyright 2022 PT Pola Proserve Engineering, All Right Reserved
        </p>
      </Grid>
    </>
  );
}

const mapStateToProps = ({ dataCategoryProduct, dataService, dataAboutUs }) => {
  return {
    dataCategoryProduct,
    dataService,
    dataAboutUs,
  };
};

export default connect(mapStateToProps)(Index);
