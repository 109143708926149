const defaultState = {
  userId: null,
  fullname: null,
  loading: false,
  error: {},
  isLogin: false,
  dataLocation: [],
  dataCategoryProduct: [],
  dataService: [],
  dataBrand: [],
  dataProduct: [],
  dataProject: [],
  dataCategoryProject: [],
  dataAboutUs: [],
  dataNews: [],
  dataEvent: [],
  dataBanner: [],
  dataMetaTag: [],
  dataFacilities: []
}

function reducer(state = defaultState, action) {
  switch (action.type) {
    case 'SET_USER': {
      return {
        ...state,
        userId: action.payload.id,
        fullname: action.payload.full_name,
      }
    }
    case 'FETCH_DATA_SUCCESS': {
      return {
        ...state, ...action.payload, loading: false,
      }
    }
    case 'USER_LOGOUT': {
      return {
        userId: null,
        fullname: null,
        loading: false,
        error: {},
        isLogin: false,
      }
    }
    case 'FETCH_DATA_LOADING': {
      return {
        ...state, loading: true
      }
    }
    case 'FETCH_DATA_ERROR': {
      return {
        ...state, error: action.payload, loading: false
      }
    }
    default:
      return state
  }
}

export default reducer