import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";

const Dashboard = lazy(() =>
  import(/* webpackChunkName: "dashboard" */ "../views/Dashboard")
);
const Contact = lazy(() =>
  import(/* webpackChunkName: "contact" */ "../views/Contact")
);
const Product = lazy(() =>
  import(/* webpackChunkName: "product" */ "../views/Product")
);
const DetailProduct = lazy(() =>
  import(/* webpackChunkName: "product" */ "../views/Product/DetailProduct")
);
const Service = lazy(() =>
  import(/* webpackChunkName: "product" */ "../views/Service")
);
const Project = lazy(() =>
  import(/* webpackChunkName: "product" */ "../views/Project")
);
const DetailProject = lazy(() =>
  import(/* webpackChunkName: "product" */ "../views/Project/DetailProject")
);
const AboutUs = lazy(() =>
  import(/* webpackChunkName: "product" */ "../views/AboutUs")
);
const DetailNewsEvent = lazy(() =>
  import(/* webpackChunkName: "product" */ "../views/AboutUs/DetailNewsEvent")
);
const Facility = lazy(() =>
  import(/* webpackChunkName: "product" */ "../views/Facility")
);

function Routes() {
  return (
    <main style={{ marginTop: 64 }}>
      <Suspense fallback={<></>}>
        <Switch>
          <Route path="/contact-us" component={Contact} />
          <Route path="/product/:id" component={DetailProduct} />
          <Route path="/product" component={Product} />
          <Route path="/service" component={Service} />
          <Route path="/project/:id" component={DetailProject} />
          <Route path="/project" component={Project} />
          <Route path="/about-us/news/:id" component={DetailNewsEvent} />
          <Route path="/about-us/event/:id" component={DetailNewsEvent} />
          <Route path="/about-us" component={AboutUs} />
          <Route path="/about-us/:id" component={AboutUs} />
          <Route path="/facility/:id" component={Facility} />
          <Route path="/facility" component={Facility} />
          <Route path="/" exact component={Dashboard} />
        </Switch>
      </Suspense>
    </main>
  );
}

export default Routes;
